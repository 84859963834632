import { CloseOutlined, ReloadOutlined, SnippetsOutlined } from "@ant-design/icons";
import { Button, Descriptions, Empty, Modal, Space, Spin, Table, Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import moment from "moment";
import React, { FC, useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import MainLayout from "../../components/main-layout/main-layout";
import { getBillsToday, logout } from "../../services/services";
import { utils } from "../../services/utils";
import { BetType } from "../../types/bet.type";
import { BillType } from "../../types/bill.type";
import './bet-report.css';

const { Title, Text } = Typography;

const setBillColumns = (onShowBetsInBill: Function) => {
    const columns: ColumnsType<BillType> = [
        // {
        //     title: '#',
        //     dataIndex: 'id',
        //     key: 'id',
        //     align: 'center',
        //     render: (text: string, record: BillType, index: number) => {
        //         return (
        //             <>
        //                 {record.status === 'inactive' ? <><CloseOutlined style={{ color: '#ff4d4f' }} /> {index + 1}</> : index + 1}
        //             </>
        //         );
        //     }
        // },
        {
            title: <b>งวดวันที่</b>,
            dataIndex: 'period',
            key: 'period',
            render: (text: string, record: BillType, index: number) => {
                return moment(record.period.dateAnnounce).format("วันที่ DD-MM-YYYY");
            }
        },
        {
            title: <b>เวลาแทง</b>,
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (text: string, record: BillType, index: number) => {
                return moment(record.createdAt).format("DD-MM-YYYY HH:mm:ss");
            }
        },
        {
            title: <b>ตลาด</b>,
            dataIndex: 'market',
            key: 'market',
            render: (text: string, record: BillType, index: number) => {
                return (<><img src={process.env.REACT_APP_API_URL + record.period.lottoMaster.pic} width={40} height={30} /> {record.period.lottoMaster.name}</>)
            }
        },
        {
            title: <b>หมายเหตุ</b>,
            dataIndex: 'detail',
            key: 'detail',
            align: 'center',
            render: (text: string, record: BillType, index: number) => {
                return text ? text : '-'
            }
        },
        {
            title: <b>ยอดเงิน</b>,
            dataIndex: 'price',
            key: 'price',
            align: 'center',
        },
        {
            title: <b>การแทง</b>,
            dataIndex: 'betType',
            key: 'betType',
            render: (text: string, record: BillType, index: number) => {
                return (
                    <>
                        <Space size="small" align="center">
                            <Button size="small" type="primary" onClick={() => onShowBetsInBill(record)}><SnippetsOutlined /></Button>
                        </Space>
                    </>
                )
            }
        },
    ];
    return columns;
}

const BetReport: FC = () => {

    const betColumns: ColumnsType<any> = [
        {
            title: <b>ประเภท @ หมายเลข</b>,
            dataIndex: 'numbersBetDataMaster',
            key: 'numbersBetDataMaster',
            align: 'center',
            render: (text: string, record: BetType, index: number) => {
                return record.betDataMaster.name + " @ " + record.numbers;
            }
        },
        {
            title: <b>ยอด</b>,
            dataIndex: 'price',
            key: 'price',
            align: 'right',
            className: 'price',
            render: (text: string, record: BetType, index: number) => {
                return utils.numberWithCommas(text);
            }
        },
        {
            title: <b>ค่าคอมมิชชั่น</b>,
            dataIndex: 'commission',
            key: 'commission',
            align: 'right',
            className: 'commission',
            render: (text: string, record: BetType, index: number) => {
                const commission = (record.betDataMaster.commissionRateMaster * 1.00) * (record.price / 100.00);
                return utils.numberWithCommas(commission + "");
            }
        },
        {
            title: <b>คงเหลือ</b>,
            dataIndex: 'total',
            key: 'total',
            align: 'right',
            className: 'total',
            render: (text: string, record: BetType, index: number) => {
                const commission = (record.betDataMaster.commissionRateMaster * 1.00) * (record.price / 100.00);
                return utils.numberWithCommas((record.price - commission) + "");
            }
        },
        {
            title: <b>อัตราจ่าย</b>,
            dataIndex: 'price',
            key: 'price',
            align: 'right',
            render: (text: string, record: BetType, index: number) => {
                return record.betDataMaster.earnPrice;
            }
        },
        {
            title: <b>รวม</b>,
            dataIndex: 'net',
            key: 'net',
            align: 'right',
            className: 'net',
            render: (text: string, record: BetType, index: number) => {
                return utils.numberWithCommas((record.price * record.betDataMaster.earnPrice) + "");
            }
        },
    ];

    let history = useHistory();

    const [loading, setLoading] = useState(true);
    const [isShowBill, setIsShowBill] = useState(false);
    const [currentBetsInBill, setCurrentBetsInBill] = useState<any>([]);
    const [currentBill, setCurrentBill] = useState<BillType>();
    const [allBill, setAllBill] = useState(null);

    const getAllBillsToDay = useCallback(async (memberId: string | null) => {
        const response = await getBillsToday(memberId);
        setAllBill(response.data);
    }, []);

    useEffect(() => {
        initData();
    }, [history, getAllBillsToDay]);

    const initData = () => {
        setLoading(true);
        getAllBillsToDay(localStorage.getItem('memberId')).then(() => setLoading(false)).catch((v) => {
            setLoading(false);
            logout();
            return history.push('/login');
        });
    }

    const rowSetup = (record: BillType, index: number) => {
        if (record.status === 'inactive') {
            return 'inactive-bill';
        }
        return '';
    }

    const onShowBetsInBill = (bill: BillType) => {
        setIsShowBill(true);
        setCurrentBill(bill);
        setCurrentBetsInBill(bill.bets);
    }



    const billInCurrentPeriod = () => {
        if (!allBill) return [];

        const data: BillType[] = allBill['data'];
        return data;
    }

    const renderBets = () => {
        return [...currentBetsInBill];
    }

    const renderSummary = (dataSummary: readonly BetType[]) => {

        let price = 0;
        let commission = 0;
        let total = 0;
        let net = 0;

        dataSummary.forEach((v: BetType) => {
            price += v.price;
            const commissionCal = (v.betDataMaster.commissionRateMaster * 1.00) * (v.price / 100.00);
            commission += commissionCal;
            total += (v.price - commissionCal);
            net += (v.price * v.betDataMaster.earnPrice);
        })

        return (
            <Table.Summary>
                <Table.Summary.Row>
                    <Table.Summary.Cell index={0} align="center">
                        <span style={{ fontWeight: 'bold' }}>รวม @ {dataSummary.length} รายการ</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={1} align="right" className="price">
                        {utils.numberWithCommas(price + "")}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={2} align="right" className="commission">
                        {utils.numberWithCommas(commission + "")}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={3} align="right" className="total">
                        {utils.numberWithCommas(total + "")}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={4} align="right" className="price">
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={5} align="right" className="net">
                        {utils.numberWithCommas(net + "")}
                    </Table.Summary.Cell>
                </Table.Summary.Row>
            </Table.Summary>
        )

    }

    return (
        <Spin spinning={loading}>
            <MainLayout menu="bet-report">
                <Title level={4}>รายการแทง</Title>
                <Button type="primary" style={{ marginBottom: 10 }} onClick={() => initData()}><ReloadOutlined /> รีเฟรช</Button>
                <Table rowClassName={rowSetup} columns={setBillColumns(onShowBetsInBill)} dataSource={billInCurrentPeriod()} size="small" locale={{ emptyText: <Empty description={<b>ไม่มีข้อมูล</b>} /> }} pagination={false}></Table>

                <Modal title={<b>{currentBill ? currentBill.period.lottoMaster.name + " " + moment(currentBill.period.dateAnnounce).format("วันที่ DD-MM-YYYY") : 'ข้อมูลโพย'}</b>}
                    open={isShowBill}
                    maskClosable={false}
                    onOk={() => setIsShowBill(false)}
                    onCancel={() => setIsShowBill(false)}
                    width={1000}
                    okText="ปิด"
                    cancelButtonProps={{ style: { display: 'none' } }}
                >
                    <Descriptions>
                        <Descriptions.Item label="หมายเหตุ">{currentBill?.detail}</Descriptions.Item>
                    </Descriptions>
                    <Table
                        className="bet-report"
                        columns={betColumns}
                        dataSource={renderBets()}
                        size="small"
                        locale={{ emptyText: <Empty description={<b>ไม่มีข้อมูล</b>} /> }}
                        pagination={false}
                        summary={(dataSummary: readonly BetType[]) => renderSummary(dataSummary)}
                    />
                </Modal>
            </MainLayout>
        </Spin>
    )
}

export default BetReport;