import { Col, Row, Spin, Typography } from 'antd';
import moment from 'moment';
import React, { FC, useCallback, useEffect, useState } from 'react';
import MainLayout from "../../components/main-layout/main-layout";
import "../main/main.css";
import 'moment/locale/th';
import { getLottoMaster, logout } from '../../services/services';
import { LottoMaster, PeriodType } from '../../types/period.type';
import { useHistory } from 'react-router-dom';
import Notification from '../../components/notification/notification';
import LottoMasterCard from '../../components/lotto-master-card/lotto-master-card';
import HowToAnnounceCard from '../../components/howto/howto-card';

const { Title, Text, Link } = Typography;

const LottoResult: FC = () => {
    let history = useHistory();
    const token = localStorage.getItem("token");
    moment.locale('th');
    const [loading, setLoading] = useState(true);
    const [lottoMaster, setLottoMaster] = useState(null);

    const lottoMasterData = useCallback(async () => {
        const response = await getLottoMaster();
        setLottoMaster(response.data);
    }, []);

    useEffect(() => {
        if (!token) {
            localStorage.clear();
            return history.push('/login');
        }
        lottoMasterData()
            .then(() => setLoading(false))
            .catch((v) => {
                setLoading(false);
                logout();
                return history.push('/login');
            });
    }, [lottoMasterData]);

    const goLink = (period: PeriodType) => {
        if (period.status === 'inactive') {
            Notification('error', period.lottoMaster?.name + 'ปิดรับแล้ว', 'แจ้งเตือนจากระบบ');
            return '#'
        }

        return history.push({
            pathname: '/bet',
            state: {
                period
            }
        })
    }

    const renderLottoMaster = (lottoType: number) => {
        if (!lottoMaster) return <></>;
        const data: LottoMaster[] = lottoMaster['data'];

        return data.map((lotto: LottoMaster, index: number) => {
            if (lotto.lottoType.id === lottoType) {
                return <Col span={6} md={6} sm={6} xs={24}>
                    {/* <Link onClick={() => goLink(period)}> */}
                    <LottoMasterCard key={index} lottoMaster={lotto} />
                    {/* </Link> */}
                </Col>
            }
        });
    }

    return (
        <Spin spinning={loading}>
            <MainLayout menu="lotto-result">
                <Title>ตรวจสอบผลรางวัล</Title>

                <div className="lotto-section">
                    <Title level={4}>หวยทั่วไป</Title>
                    {lottoMaster ? renderLottoMaster(2) : <></>}
                </div>

                <div className="lotto-section">
                    <Title level={4}>หวยรายวัน</Title>
                    {lottoMaster ? renderLottoMaster(1) : <></>}
                </div>

                <div className="lotto-section">
                    <Title level={4}>หวยหุ้น Gold</Title>
                    {lottoMaster ? renderLottoMaster(3) : <></>}
                </div>

                <div className="lotto-section">
                    <HowToAnnounceCard />
                </div>
            </MainLayout>
        </Spin>
    );


}

export default LottoResult;