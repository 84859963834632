import React, { FC } from 'react';
import moment from 'moment';
import { Space, Typography } from 'antd';
import { LottoMaster } from '../../types/period.type';
import { CheckOutlined, CloseOutlined, LinkOutlined } from '@ant-design/icons';

const { Title, Text, Link } = Typography;

type Props = {
    lottoMaster: LottoMaster
}


const LottoMasterCard: FC<Props> = ({ lottoMaster }) => {
    return (
        <Space direction="vertical" style={{ marginBottom: 20, fontSize: 16 }}>
            <Text strong>ลิ้งดูผล{lottoMaster.name}</Text>
            <Text><CloseOutlined style={{ fontWeight: 'bold', color: '#000' }} /> ปิดรับ {lottoMaster.closeDateDescription}</Text>
            <Text><CheckOutlined style={{ fontWeight: 'bold', color: 'gray' }} /> ผลออก {lottoMaster.announceDateDescription}</Text>
            <Text><LinkOutlined style={{ fontWeight: 'bold', color: 'red' }} /> ลิ้งดูผล <Link href={lottoMaster.link} target="_blank">{lottoMaster.link}</Link></Text>
        </Space>
        
        // <a href={lottoMaster.link} target="_blank">
        //     <div className="lotto-card">
        //         <div className="header">
        //             <img src={process.env.REACT_APP_API_URL + lottoMaster?.pic} style={{ width: 100, height: 80, margin: 'auto' }} alt="line lotto" />
        //             <Title level={5} style={{ margin: 'auto', textAlign: 'center' }}>หวย{lottoMaster?.name}</Title>
        //         </div>
        //         {/* <div className="content-card">
        //         <Title level={5} style={{ margin: 'auto', textAlign: 'center' }}>งวด {periodDate}</Title>
        //     </div> */}
        //     </div>
        // </a>

    )
}

export default LottoMasterCard;