import { FC } from "react";
import BetReport from "../pages/bet-report/betReport";
import Bet from "../pages/bet/bet";
import Login from "../pages/login/login";
import Main from "../pages/main/main";
import Report from "../pages/report/report";
import Result from "../pages/result/result";
import ResultBill from "../pages/result/result-bill/resultBill";
import Rules from "../pages/rules/rules";
import LottoResult from "../pages/lotto-result/lotto-result";

export type MyRoute = {
  path: string,
  exact: boolean,
  name: string,
  label?: string,
  isVisible: boolean,
  roles?: string[],
  component: FC,
  nestedRoutes?: MyRoute[]
};

const routes: MyRoute[] = [
  {
    path: '/',
    exact: true,
    name: 'main',
    label: 'หน้าหลัก',
    isVisible: true,
    roles: ['member', 'header'],
    component: Main,
  },
  {
    path: '/login',
    exact: true,
    name: 'login',
    isVisible: true,
    label: '',
    component: Login,
  },
  {
    path: '/rules',
    exact: true,
    name: 'rules',
    label: 'กติกาการเล่น',
    isVisible: true,
    roles: ['header', 'member'],
    component: Rules,
  },
  {
    path: '/bet-report',
    exact: true,
    name: 'bet-report',
    label: 'รายการแทง',
    isVisible: true,
    roles: ['header', 'member'],
    component: BetReport,
  },
  {
    path: '/bet',
    exact: true,
    name: 'bet',
    label: 'แทงหวย',
    isVisible: true,
    roles: ['header', 'member'],
    component: Bet,
  },
  {
    path: '/result',
    exact: true,
    name: 'result',
    label: 'สรุปยอด',
    isVisible: true,
    roles: ['header', 'member'],
    component: Result,
    nestedRoutes: [
      {
        path: '/result/bill',
        exact: true,
        name: 'bill',
        label: 'สรุปยอดแยกบิล',
        isVisible: false,
        roles: ['header', 'member'],
        component: ResultBill,
      }
    ]
  },
  {
    path: '/report',
    exact: true,
    name: 'report',
    label: 'รายงาน',
    isVisible: false,
    roles: ['header', 'member'],
    component: Report,
  },
  {
    path: '/lotto-result',
    exact: true,
    name: 'lotto-result',
    label: 'ตรวจสอบผลรางวัล',
    isVisible: false,
    roles: ['member'],
    component: LottoResult,
  },
  // {
  //   path: '/period',
  //   exact: true,
  //   name: 'period',
  //   label: 'งวดหวย',
  //   isVisible: true,
  //   roles: ['member', 'header'],
  //   component: Period,
  // },
  // {
  //   path: '/bet',
  //   exact: true,
  //   name: 'bet',
  //   label: 'แทง',
  //   isVisible: false,
  //   roles: ['member', 'header'],
  //   component: Bet,
  // },
  // {
  //   path: '/member',
  //   exact: true,
  //   name: 'member',
  //   label: 'สมาชิก',
  //   isVisible: true,
  //   roles: ['header'],
  //   component: Member,
  // },
  // {
  //   path: '/result-member',
  //   exact: true,
  //   name: 'result-member',
  //   label: 'สรุปยอด',
  //   isVisible: true,
  //   roles: ['member'],
  //   component: ResultMember,
  // },
  // {
  //   path: '/result-admin',
  //   exact: true,
  //   name: 'result-admin',
  //   label: 'สรุปยอด',
  //   isVisible: true,
  //   roles: ['header'],
  //   component: ResultAdmin,
  // },
  // {
  //   path: '/report',
  //   exact: true,
  //   name: 'report',
  //   label: 'รายงาน',
  //   isVisible: false,
  //   roles: ['header', 'member'],
  //   component: Report,
  // },
  // {
  //   path: '/announce',
  //   exact: true,
  //   name: 'announce',
  //   label: 'ออกผล',
  //   isVisible: true,
  //   roles: ['header'],
  //   component: Announce,
  // },
]


export default routes;
