import React from 'react';
import { Image, Space, Typography } from 'antd';
import nikkieGoldImage from '../../assets/images/nikkie-gold.jpg';
import szshGoldImage from '../../assets/images/szsh-gold.jpg';
import hsGoldImage from '../../assets/images/hs-gold.jpg';
import taiwanGoldImage from '../../assets/images/taiwan-gold.jpg';

const { Title } = Typography;

function HowToAnnounceCard() {
    return (
        <Typography style={{ fontSize: 16 }}>
            <Title style={{ textAlign: 'center' }}>การออกผลรางวัล</Title>
            <Space direction="horizontal" style={{ width: '100%', justifyContent: 'center', marginBottom: 5 }}>
                <Image src={nikkieGoldImage} preview={false} style={{  width: '100%', maxWidth: 800 }} />
            </Space>
            <Space direction="horizontal" style={{ width: '100%', justifyContent: 'center', marginBottom: 5 }}>
                <Image src={szshGoldImage} preview={false} style={{  width: '100%', maxWidth: 800 }} />
            </Space>
            <Space direction="horizontal" style={{ width: '100%', justifyContent: 'center', marginBottom: 5 }}>
                <Image src={hsGoldImage} preview={false} style={{  width: '100%', maxWidth: 800 }} />
            </Space>
            <Space direction="horizontal" style={{ width: '100%', justifyContent: 'center', marginBottom: 5 }}>
                <Image src={taiwanGoldImage} preview={false} style={{  width: '100%', maxWidth: 800 }} />
            </Space>
        </Typography>
    );
}

export default HowToAnnounceCard;