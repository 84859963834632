import React, { FC, useState, useEffect } from "react";
import { Layout, Button, Divider, Space, Typography, Drawer } from 'antd';
import { HomeOutlined, CloseOutlined } from '@ant-design/icons'
import './main-layout.css'
import 'moment/locale/th';
import MenuNav from "../menu-nav/menu-nav";
import DetailSider from "../detail-sider/detail-sider";
import { useHistory } from "react-router-dom";
import logo from '../../assets/images/logo_2.png';

const { Header, Sider, Content } = Layout;
const { Title } = Typography;

type Props = {
    menu: string,
    children: React.ReactNode
}

const MainLayout: FC<Props> = ({ menu, children }) => {
    
    let history = useHistory();
    const token = localStorage.getItem("token");

    const [collapsed, setCollapsed] = useState(false);
    const [loadingProfile, setLoadingProfile] = useState(false);
    const [profile, setProfile] = useState({ nickname: localStorage.getItem("name") }) // useState(JSON.parse(localStorage.getItem('profile')));
    const [selected, setSelected] = useState('1');
    const [visibleEditProfileForm, setVisibleEditProfileForm] = useState(false);
    const [role, setRole] = useState(localStorage.getItem("role")) // useState(profile ? Buffer.from(profile.role.substring(1, profile.role.length - 2), 'base64').toString('utf8') : "");
    const [isMobile, setIsMobile] = useState(false);
    const [time, setTime] = useState(Date.now());
    const [open, setOpen] = useState(false);

    const resizeWindow = () => {
        const width = window.innerWidth;
        if (width <= 470) {
            setIsMobile(true);
            setCollapsed(true);
        }
        else {
            setIsMobile(false);
            setCollapsed(false);
        }
    }

    useEffect(() => {        
        if (!token) {
            return history.push("/login");
        }

        resizeWindow();
        window.addEventListener("resize", resizeWindow);
        const interval = setInterval(() => setTime(Date.now()), 1000);

        return () => {
            window.removeEventListener("resize", resizeWindow);
            clearInterval(interval);
        }
    }, [token, history]);

    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    return (
        <Layout className="layout">
            {isMobile ? <></> :
                <Sider trigger={null} collapsible collapsed={collapsed} style={{ maxWidth: 120, minWidth: 120 }}>
                    <div className="sider"><img src={logo} style={{ width: '100%', height: 60 }} alt="real lotto" /></div>
                    <Divider style={{ backgroundColor: '#BDCCDB', margin: '0px' }} />
                    <DetailSider time={time} />
                </Sider>}

            <Layout style={{ width: '100%', backgroundColor: '#dff6ff' }}>
                <Header style={{ padding: 0, backgroundColor: '#fff' }}>
                    {isMobile ?
                        <Button style={{ margin: '15px 10px 10px 10px' }} onClick={showDrawer}>
                            <HomeOutlined />
                        </Button> : <></>}

                    {isMobile ?
                        <Drawer
                            title={<img src={logo} style={{ width: '100%', height: 80, paddingRight: 5 }} alt="real lotto" />}
                            placement={'left'}
                            closable={false}
                            onClose={onClose}
                            open={open}
                            key={'left'}
                            extra={
                                <Space>
                                    <CloseOutlined onClick={onClose} style={{ color: "#fff", fontSize: 20, cursor: "pointer" }} />
                                </Space>
                            }
                        >
                            <DetailSider time={time} />
                        </Drawer> : <></>}

                    <MenuNav menu={menu} type={isMobile ? 'dropdown' : 'radio'} />
                </Header>
                <Content className="content" style={{ margin: isMobile ? 0 : 20 }}>
                    {children}
                </Content>
            </Layout>
        </Layout>
    )
}

export default MainLayout;