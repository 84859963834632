import { MenuOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu, Radio, RadioChangeEvent } from 'antd';
import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';

type Props = {
    menu: string,
    type: string
}

const items = [
    {
        key: 'bet',
        label: 'แทงหวย',
        route: 'bet'
    },
    {
        key: 'rules',
        label: 'กติกาการเล่น',
        route: 'rules'
    },
    {
        key: 'result',
        label: 'สรุปยอด',
        route: 'result'
    },
    {
        key: 'document',
        label: 'คู่มือการใช้งาน',
        route: 'document'
    }
];

const MenuNav: FC<Props> = ({ menu, type }) => {
    let history = useHistory();

    const goRoute = (type: string, e: RadioChangeEvent | any) => {
        let menuName = '/';
        if (type === 'radio') {
            menuName = '/' + e.target.value;
            if (menuName === '/bet') menuName = '/';
        } else {
            menuName = '/' + e.key;
            if (menuName === '/bet') menuName = '/';
        }

        // console.log(history.location);
        
        // window.location.href = menuName;
        history.push(menuName);
        // history
    }

    if (type === 'radio') {
        return (
            <Radio.Group defaultValue={menu} buttonStyle="solid" style={{ marginLeft: 20, borderRadius: 8 }} onChange={(e) => goRoute('radio', e)}>
                <Radio.Button value="bet">แทงหวย</Radio.Button>
                <Radio.Button value="bet-report">รายการแทง</Radio.Button>
                <Radio.Button value="rules">กติกาการเล่น</Radio.Button>
                <Radio.Button value="result">บัญชีการเงิน</Radio.Button>
                <Radio.Button value="document">คู่มือการใช้งาน</Radio.Button>
            </Radio.Group>
        )
    }

    return (<Dropdown overlay={<Menu items={items} onClick={(e) => goRoute('dropdown', e)} />} placement="bottomLeft">
        <Button style={{ float: 'right', marginRight: 10, marginTop: 15 }}>
            <MenuOutlined />
        </Button>
    </Dropdown>)

}

export default MenuNav;