import React, { Component } from 'react';
import { CheckOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { notification } from "antd";

const Notification = (type: string, description: string, title?: string) => {
    if (type === 'success') {
        notification.success({
            message: title ? title : 'ข้อความจากระบบ',
            description: description,
            duration: 2,
            icon: <CheckOutlined style={{ color: '#52c41a' }} />
        });
    } else if (type === 'error') {
        notification.error({
            message: title ? title : 'ข้อความจากระบบ',
            description: description,
            duration: 2,
            icon: <CloseCircleOutlined style={{ color: '#ff4d4f' }} />
        });
    }
};

export default Notification;